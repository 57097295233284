@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Exo", sans-serif;
}

/* carousel style*/

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

/* Buttons */
.btn {
  @apply inline-flex items-center py-3 px-4 rounded-lg;
}

.btn-warning {
  @apply bg-yellow-500 hover:bg-yellow-600 text-white;
}

.btn-danger {
  @apply bg-red-500 hover:bg-red-600 text-white;
}

.btn-success {
  @apply bg-green-500 hover:bg-green-600  text-white;
}

.btn-default {
  @apply bg-gray-500 hover:bg-gray-600  text-white;
}

.btn-primary {
  @apply bg-yellow-500 hover:bg-yellow-600 text-white;
}

.btn-secondary {
  @apply bg-transparent hover:bg-transparent text-blue-800 border-2 border-blue-800 font-semibold;
}

.btn-secondary:hover {
  @apply bg-blue-800  hover:bg-blue-800  text-white border-2 border-white font-semibold;
}

.btn-secondary-2 {
  @apply bg-blue-800  hover:bg-blue-800  text-white border-2 border-white font-semibold;
}

.btn-icon {
  @apply w-4 h-4;
}

.btn-sm {
  @apply py-1 px-2 rounded text-sm;
}

.btn-sm .btn-icon {
  @apply w-3 h-3;
}

.btn-h-view-all {
  @apply py-5 px-8 rounded-lg;
}

/* =========================================== */
.section_padding {
  @apply py-16 px-12;
}

/* ============================================ */
.h_nav_link {
  @apply text-gray-900 px-3 py-2 font-semibold flex;
}

.h_submenu {
  @apply hidden absolute top-[100%] left-0 z-40 min-w-[205px] shadow-lg rounded-lg bg-gray-50 overflow-hidden;
}

.h_nav_item {
  @apply relative;
}

.h_nav_item:hover .h_submenu {
  display: block;
}

.h_submenu .h_nav_link {
  @apply py-4 px-2 block;
}

.h_submenu .h_nav_link:hover {
  @apply bg-gray-300;
}

/* Banner */
.hero_img {
  height: 400px;
  object-fit: contain;
}

/* Footer */
.footer-social-icon {
  @apply w-5 h-5;
}

/* FAQ Ant design */
.faq_h_section .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  @apply text-white;
}

.faq_h_section .ant-collapse-content-box {
  @apply text-white;
}

.faq_tabs {
  @apply flex justify-center gap-x-2 mb-4;
}

.faq_tabs > button {
  @apply bg-yellow-500 text-white rounded-2xl focus:ring-0;
}

@media (max-width: 1150px) {
  .header {
  }

  .header__menucol {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 300px;
    height: 100vh;
    overflow-y: auto;
    background-color: #010b2d;
    z-index: 10;
    box-shadow: 0px 0px 10px 0px rgb(25, 25, 25);
    flex-direction: column;
    padding: 20px 10px;
    justify-content: flex-start !important;
    display: none !important;
    transition: all 0.2s ease-in-out;
  }

  .header__menushow {
    display: flex !important;
  }
  .header__hideshow {
    display: none !important;
  }

  .header__menu {
    flex-direction: column;
    align-items: flex-start;
  }

  .header__menu li {
    display: block;
    flex: 1;
    width: 100%;
  }

  .menu__homeicon {
    display: none;
  }

  .header__menu a {
    padding: 12px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
  }

  .h_submenu {
    position: relative;
    top: unset;
  }

  .menu__buttoncol {
    display: block !important;
  }

  .h_submenu .h_nav_link {
    color: black;
  }
}

@media (max-width: 460px) {
  .section_padding {
    @apply py-8 px-6;
  }

  .hero_img {
    height: 200px;
    object-fit: contain;
  }

  .btn-h-view-all {
    @apply py-3 px-5 rounded-lg;
  }
}
